<template>
	<el-dialog :visible.sync="show" :show-close="false" :close-on-click-modal="false" :before-close="close">
		<div slot="title">
			<div class="titleLeft">
				{{i18n('296')}}
			</div>
			<div class="titleRight" @click="close">
				<img src="@/assets/close.png" alt="">
			</div>
		</div>
		<div class="je">
			<span>₱</span> {{infoData.money}}
		</div>
		<div class="list">
			<div class="item">
				<div class="left">
					{{i18n('297')}}：
				</div>
				<div class="right">
					{{infoData.create_time*1000 | formatTime('YMD')}}
				</div>
			</div>
			<div class="item">
				<div class="left">
					{{i18n('298')}}：
				</div>
				<div class="right">
					{{infoData.pay_num || '--'}}
				</div>
			</div>
			<div class="item">
				<div class="left">
					{{i18n('181')}}：
				</div>
				<div class="right" v-if="infoData.pay_method==1">
					{{i18n('200')}}
				</div>
				<div class="right" v-if="infoData.pay_method==2">
					{{i18n('201')}}
				</div>
				<div class="right" v-if="infoData.pay_method==6">
					grabpay
				</div>
				<div class="right" v-if="infoData.pay_method==7">
					gcash
				</div>
				<div class="right" v-if="infoData.pay_method==8">
					visa
				</div>
				<div class="right" v-if="infoData.pay_method==4">
					{{i18n('203')}}
				</div>
				<div class="right" v-if="infoData.pay_method==3">
					{{i18n('203')}}
				</div>
				<div class="right" v-if="infoData.pay_method==5">
					{{i18n('299')}}
				</div>
			</div>
			<!-- <div class="item">
				<div class="left">
					备注：
				</div>
				<div class="right">
					
				</div>
			</div> -->
			<div class="item">
				<div class="left">
					{{i18n('180')}}：
				</div>
				<div class="right">
					{{infoData.order_number || '--'}}
				</div>
			</div>
			<div class="item">
				<div class="left">
				{{i18n('300')}}
				</div>
				<div class="right" style="color: #56AB7B;">
					{{i18n('301')}}
				</div>
			</div>
		</div>
		<div class="but" @click="close">
			{{i18n('56')}}
		</div>
	</el-dialog>
</template>

<script>
	import {rechargeInfo} from "@/api/recharge.js"
	export default {
		name: "details",
		data() {
			return {
				infoData:false,
				show: false
			}
		},
		methods: {
			async open(id) {
				await this.rechargeInfo(Number(id))
				this.show = true
			},
			async rechargeInfo(id){
				let {data} = await rechargeInfo({id})
				this.infoData = data
			},
			close() {
				this.show = false
			},
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
			
		},
		mounted() {
			
		}
	};
</script>

<style scoped>
	.but{
		width: 226px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		line-height: 44px;
		margin: 0 auto;
		margin-top: 50px;
		cursor: pointer;
	}
	.list{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.list .item{
		margin-bottom: 24px;
		display: flex;
		align-items: center;
		width: 50%;
	}
	.list .item .left{
		width: 86px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}
	.list .item .right{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
	}
	.je{
		padding-top: 8px;
		text-align: center;
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #F23725;
		font-weight: 500;
		margin-bottom: 16px;
	}
	.je span{
		font-family: Helvetica;
		font-size: 20px;
		color: #F23725;
		font-weight: 400;
	}
	>>>.el-dialog__body {
		padding: 0 32px 60px 32px;
	}

	>>>.el-dialog {
		width: 662px;
		background: #FFFFFF;
		border-radius: 20px;
	}

	>>>.el-dialog__header {
		padding: 0 32px;
	}

	>>>.el-dialog__header div {
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	>>>.el-dialog__header .titleLeft {
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #000000;
		letter-spacing: 0;
		font-weight: 500;
	}

	>>>.el-dialog__header .titleRight {
		width: 24px;
		height: 24px;
		background: #F2F3F5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
	}

	>>>.el-dialog__header .titleRight img {
		width: 12px;
		height: 12px;
		display: block;
	}

</style>
