import fetch from '@/utils/fetch.js';

//明细列表
export function rechargeList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/recharge/capital/list",
		method: 'POST',
		data
	})
}
//明细
export function rechargeInfo(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/recharge/capital/info",
		method: 'POST',
		data
	})
}
//充值
export function submit(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/recharge/submit",
		method: 'POST',
		data
	})
}
//获取openid
export function getOpenid(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/recharge/openid",
		method: 'POST',
		data
	})
}
//充值结果
export function rechargeResult(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/recharge/result",
		method: 'POST',
		data
	})
}
//重新支付
export function rechargeAgain(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/recharge/again",
		method: 'POST',
		data
	})
}