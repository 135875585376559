<template>
	<div class="record">
		<div class="title">
			{{i18n('25')}}
		</div>
		<div class="inner">
			<div class="tab">
				<div :class="params.genre==0?'item itemAc':'item'" @click="genreChange(0)">
					{{i18n('309')}}
				</div>
				<div :class="params.genre==1?'item itemAc':'item'" @click="genreChange(1)">
					{{i18n('315')}}
				</div>
			</div>
			<div class="tableTitle listItem">
				<div class="item">
					{{i18n('316')}}
				</div>
				<div class="item">
					{{i18n('317')}}
				</div>
				<div class="item">
					{{i18n('181')}}
				</div>
				<div class="item">
					{{i18n('318')}}
				</div>
				<div class="item">
					{{i18n('319')}}
				</div>
			</div>
			<div class="list">
				<div class="listItem" v-for="(item,index) in listData" :key="index" @click="detailsOpen(item.id)">
					<div class="item">
						{{item.create_time*1000 | formatTime('YMDHMS')}}
					</div>
					<div class="over item" v-if="item.purpose==0">
						{{i18n('320')}}
					</div>
					<div class="over item" v-if="item.purpose==1">
						{{i18n('38')}}
					</div>
					<div class="over item" v-if="item.purpose==2">
						{{i18n('321')}}
					</div>
					<div class="over item" v-if="item.purpose==4">
						{{i18n('299')}}
					</div>
					<div class="over item" v-if="item.purpose==5">
						{{i18n('322')}}
					</div>
					<div class="over item" v-if="item.purpose==8">
						{{i18n('299')}}
					</div>
					<div class="over item" v-if="item.purpose==9">
						{{i18n('323')}}
					</div>
					<div class="over item" v-if="item.purpose==10">
						{{i18n('324')}}
					</div>
					
					<div class="item" v-if="item.pay_method==1">
						{{i18n('200')}}
					</div>
					<div class="item" v-if="item.pay_method==2">
						{{i18n('201')}}
					</div>
					<div class="item" v-if="item.pay_method==6">
						grabpay
					</div>
					<div class="item" v-if="item.pay_method==7">
						gcash
					</div>
					<div class="item" v-if="item.pay_method==8">
						visa
					</div>
					<div class="item" v-if="item.pay_method==4">
						{{i18n('203')}}
					</div>
					<div class="item" v-if="item.pay_method==3">
						{{i18n('202')}}
					</div>
					<div class="item" v-if="item.pay_method==5">
						{{i18n('299')}}
					</div>
					
					
					<div class="item" v-if="((item.status!=2 && params.genre==0) || (item.status!=9 && params.genre!=0)) && (item.status!=0)">
						{{i18n('325')}}
					</div>
					<div class="item" v-if="item.status==2 && params.genre==0" style="color: #F23725;">
						{{i18n('4048')}}
					</div>
					<div class="item" v-if="item.status==0 && item.purpose==1" style="color: #F23725;">
						{{i18n('4048')}}
					</div>
					<div class="item" v-if="item.status==9 && params.genre!=0" style="color: #F39E4E;">
						{{i18n('326')}}
					</div>
					<div class="item">
						{{item.money}}₱
					</div>
				</div>
				<div class="noData" v-if="noData">
					<div class="">
						<img src="@/assets/noData2.png" alt="">
						<div class="text">
							{{i18n('327')}}
						</div>
					</div>
				</div>
				
				<div class="pagination" v-if="total!=0">
					<el-pagination layout="prev, pager, next" :total="total" :page-size="params.page_size" :current-page='params.page_num'
						@current-change="currentChange">
					</el-pagination>
				</div>
			</div>
		</div>
		<detailsss ref="details"></detailsss>
	</div>
</template>

<script>
	import {
		rechargeList
	} from "@/api/recharge.js"
	
	import detailsss from "./components/details.vue"
	export default {
		name: "record",
		components:{
			detailsss
		},
		data() {
			return {
				params: {
					page_num: 1,
					page_size: 7,
					genre: 0, //0充值   1消费
					language: 0
				},
				noData: false,
				total:0,
				listData: [],
			}
		},
		methods: {
			detailsOpen(id){
				this.$refs.details.open(id)
			},
			init() {
				let lang = this.$store.state.i18n.lang
				if (lang == 'zh') {
					this.params.language = 0
				} else if (lang == 'en') {
					this.params.language = 1
				} else if (lang == 'vie') {
					this.params.language = 2
				}
				this.params.page_num = 1
				this.rechargeList()
			},
			currentChange(page_num){
				this.params.page_num = page_num
				this.rechargeList()
			},
			async rechargeList() {
				let {
					data,
					total
				} = await rechargeList(this.params)
				this.listData = data
				this.total = total
				if (total == 0) {
					this.noData = true
				} else {
					this.noData = false
				}
			},
			genreChange(type) {
				this.params.genre = type
				this.params.page_num = 1
				this.rechargeList()
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		watch: {
			lang() {
				this.init()
			}
		},
		created() {
			this.init()
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.noData{
		width: 100%;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.noData img{
		width: 100px;
		height: 100px;
		display: block;
		margin: 0 auto;
	}
	.noData .text{
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		line-height: 20px;
		font-weight: 400;
		text-align: center;
		margin-top: 10px;
	}
	.list {
		flex: 1;
		overflow-y: scroll;
	}

	.list .listItem {
		background: rgba(86, 171, 123, 0.10);
		cursor: pointer;
	}

	.list .listItem:nth-child(2n) {
		background: #fff;
	}

	.listItem {
		display: flex;
		align-items: center;
		height: 46px;
		border-bottom: 1px solid rgba(241, 241, 241, 1);
	}

	.listItem .item {
		flex: 1;
		text-align: center;
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #1D2129;
		text-align: center;
		font-weight: 400;
	}

	.listItem .item:nth-child(1) {
		flex: 2;
	}

	.tableTitle .item {
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		font-weight: 400;
	}

	.tab {
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(241, 241, 241, 1);
	}

	.tab .item {
		width: 104px;
		height: 38px;
		background: rgba(86, 171, 123, 0.10);
		border-radius: 12px 12px 0px 0px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #4E5969;
		line-height: 38px;
		font-weight: 500;
		text-align: center;
		cursor: pointer;
	}

	.tab .itemAc {
		background: #56AB7B;
		color: #FFFFFF;
	}

	.inner {
		display: flex;
		flex-direction: column;
		flex: 1;
		width: 1032px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 24px;
		box-sizing: border-box;
	}

	.title {
		height: 69px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		line-height: 69px;
		font-weight: 500;
	}

	.record {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow-y: scroll;
	}
	>>>.el-pagination .btn-next {
		background: none;
	}
	
	>>>.el-pagination button:disabled {
		background: none;
	}
	
	>>>.el-pagination .btn-prev {
		background: none;
	}
	
	>>>.el-pager li.active {
		color: #56AB7B;
	}
	
	.pagination {
		width: 100%;
		height: 36px;
		padding-bottom: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
